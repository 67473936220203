import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-print-pdf",
  templateUrl: "./print-pdf.component.html",
  styleUrls: ["./print-pdf.component.scss"],
})
export class PrintPdfComponent implements OnInit {
  @Input() documentURL;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closeModal() {
    this.activeModal.close();
  }
}
