import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScHttpService } from '../../../services/http/sc-http.service';
import { ScAuthService } from '../../../services/auth/sc-auth.service';
import { EnvironmentConstants, StorageKeys } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListDataServiceService } from 'src/app/services/list-data-service.service';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScStorageService } from 'src/app/services/storage/sc-storage.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  pwdPattern = "((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{7,20})";

  loginForm: FormGroup;
 
  errorMessage: any;
  successMessage: any;
  authStatus: boolean;
  postLoginURL: string ="";
  redirectUrls = {
    admin:"shipcarte/dashboard",
    salesRep:"shipcarte/dashboard",
    operation:"shipcarte/orders",
    finance:"shipcarte/allinvoices"
  }

  EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-@ ]+$/i;

  constructor(private router: Router, private storageService: ScStorageService ,private _formBuilder: FormBuilder, private scHttpService: ScHttpService, private scAuthService: ScAuthService,private modalService:NgbModal,private listDataService:ListDataServiceService,private commanService:ScCommonService) { 
    console.log("check login ")
  }
  dashboardShareData:any;
  ngOnInit() {
    console.log('hello')
    localStorage.removeItem('setpOneData')
    this.commanService.dashboardData.subscribe((data=>{
      this.dashboardShareData=data;
      console.log(this.dashboardShareData.sideValue)
    }))
    this.dashboardShareData.sideValue=[]
    console.log(this.dashboardShareData.sideValue)
    this.modalService.dismissAll()
    this.listDataService.removeAllListData()
    let isUserAlreadyLogin = this.scAuthService.isLoggedSession();
    // let url=window.location.href.split('#');
   
    if (isUserAlreadyLogin != '') {
      // let url=window.location.href.split('#');
      // let mainUrl=url[0].split('/');
      // console.log(mainUrl);
      // console.log('http://'+mainUrl[1]+'#/shipcarte/dashboard')
      // window.history.back();
      // window.location.href='https://'+mainUrl[1]+'#/shipcarte/dashboard'
      // this.router.navigateByUrl('shipcarte/dashboard');
      this.naviagteToDefaultRoute();
    }
    // this.scAuthService.clearAllStorage();
    this.postLoginURL = "shipcarte/dashboard";
    this.loadFormData();
  }

  naviagteToDefaultRoute() {
    const role = this.storageService.getBucket(StorageKeys.LOGGEDIN_USER_TYPE);
    const redirectUrl = this.redirectUrls[role] || 'shipcarte/dashboard';
    this.router.navigateByUrl(redirectUrl);
  }


  loadFormData() {

    this.loginForm = this._formBuilder.group({

      email: ['', [Validators.required, Validators.pattern(this.EMAIL_REGEXP)]],
      password: ['', Validators.required]

    });

  }

  get f() { return this.loginForm.controls; }

  processing = false;
  procesing_img = EnvironmentConstants.IMAGE_LOADER;

  isAdminLogin: any;

  checkAdmin(event) {
    console.log(event)
    this.isAdminLogin = event;
  }
  authUser() {

    this.processing = true;

    let formData_ = this.loginForm.getRawValue();

    let email = formData_.email;
    let password = formData_.password;

    let actorType = "";
    actorType = "admin";
    let authPromise = this.scAuthService.signinUser(email, password, '', actorType, this.postLoginURL);

    authPromise.then((resolve) => {
      let response = resolve;

      this.authStatus = response["data"];

      this.processing = false;
      if (this.authStatus) {
        this.successMessage = response["message"];
        // this.router.navigateByUrl(this.postLoginURL);
        this.naviagteToDefaultRoute();
      } else {
        throw new Error("No user object found");
      }

    }).catch((error) => {

      if (error.error) {
        this.errorMessage = error.error.includes("Invalid email or password.")
          ? "Invalid username or password."
          : error.error;
      }
       else {
        this.errorMessage = error;
        // console.log(this.errorMessage)
      }

      this.processing = false;
    });

  }

}
