import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ScHttpService } from "src/app/services/http/sc-http.service";
import { ServerURLS } from "src/environments/environment";
import * as html2pdf from "html2pdf.js";
import { LoadingService } from "src/app/services/loader.service";
import { PrintPdfComponent } from "../print-pdf/print-pdf.component";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-multiple-download-bol",
  templateUrl: "./multiple-download-bol.component.html",
  styleUrls: ["./multiple-download-bol.component.scss"],
})
export class MultipleDownloadBolComponent implements OnInit {
  bolDataList: any = [];
  declaredValue = 0;
  @Input() orderData;
  // str ="b087b45f-3d1f-4026-a9b3-a2a5af55c6a0,968d2092-ba26-4faa-a46f-85ac858164a9,0e30119c-6d70-4a11-b75a-2147cd543c25,1c22a4ff-b008-4252-a0f5-b549fbd24ea5,9793e623-633f-427b-885d-59872df97958,eceb8e99-e588-4935-946c-b62b0ec5dd1f,d8d8f23b-209a-4e7d-b0db-316c9ab1b4a9,24ddfaf1-1713-4caa-ab26-7a24dbefa810,63698322-a49a-403a-b0bc-ef1e7d572ba9,b3ac9508-c05c-4c1b-9727-f6b66deee25b,f103d8ba-bc78-43ee-941b-a526785133fc,7cad70ef-e40d-4a8f-ab29-576729e2abb7,050ba7cf-ac9a-4cd6-85a0-f306ab188d45,ab8d2bce-1a69-4987-a020-f3b2a0465857,ce1dd134-74c0-43dd-99a5-f2ce99188493,af7a2a10-a5e0-4a43-8da4-8d668bf161ba,60aed4f9-469c-47f5-ad8e-29190f1cef94,feb9d722-0bf7-4b8c-9a9f-abf91f6c4614,4dea58a8-82bf-4108-9e36-3ae0de3111b0,611594d1-7a9c-43f6-b872-0076932b76aa,0de61f79-e408-4884-99da-63d30b55e4ba,5812bb2d-29de-4864-af0d-4345ccec7ad8,1414b7d3-0aec-4d54-87bb-b287fc5dcbce,3900142e-6171-40ea-bda8-022267d11565,aefde7dc-4f65-45ab-a996-e7489f122436,b40b1e91-b5d7-450a-8a52-14ce70d9bf95,6596df85-e182-4a7c-bbdf-0029da33b7dc,04d54b91-c1fb-45dd-82b3-03878fb675ba,02ff5779-e358-4372-a5ea-caa1162ce061,1bde35f9-0cad-49ca-a270-605e9fff6882";

  constructor(
    private activeModal: NgbActiveModal,
    private scHttpService: ScHttpService,
    private _loaderService: LoadingService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    this.getMultiProbillData();
  }

  // "?quoteId=11f07cd4-a9ae-4f07-b62f-5ede474148de,be5ef456-18c4-44d1-a022-a7ec7cfba6b2";

  getPackagingSummary(items = []) {
    let weightType = "Kg";
    let declaredValue = 0;

    // Group items by packagingCode and count their quantities
    const grouped = items.reduce(
      (acc, item) => {
        const packagingCode =
          item && item.packagingCode ? item.packagingCode : null;
        const qty = item && item.qty ? item.qty : 0;
        const weight = item && item.totalWeight ? item.totalWeight : 0;
        const shipmentItemValue =
          item && item.shipmentItemValue ? item.shipmentItemValue : 0;
        // Set weight type based on the first item's weight unit
        if (weightType === "Kg" && item && item.wghtUnit === "lbs") {
          weightType = "Lbs";
        }

        // Calculate declaredValue based on unit condition
        if (item && item.unit === "cm") {
          declaredValue += shipmentItemValue * qty;
        } else {
          declaredValue += shipmentItemValue;
        }

        if (packagingCode) {
          acc.packagingCounts[packagingCode] =
            (acc.packagingCounts[packagingCode] || 0) + qty;
        }
        acc.totalWeight += weight;

        return acc;
      },
      {
        packagingCounts: {} as Record<string, number>,
        totalWeight: 0,
      }
    );

    // Convert the grouped data to the desired string format
    const summary = Object.entries(grouped.packagingCounts)
      .map(
        ([key, value]) =>
          `${value} ${key.charAt(0).toUpperCase() + key.slice(1)}`
      )
      .join(", ");

    // Format totalWeight to two decimal places
    const totalWeight = grouped.totalWeight.toFixed(2);

    // Return the result as an object
    return {
      summary,
      totalWeight: `${totalWeight} ${weightType}`,
      declaredValue,
    };
  }

  getDocError() {
    this.toastr.error("No document available for selected orders.", "Oops", {
      timeOut: 10000,
    });
  }

  closeLoader() {
    this._loaderService.stringSubject.next(false);
  }

  getMultiProbillData() {
    const quoteIds = this.orderData.map((data) => data.quoteId);

    let url = ServerURLS.getMultiBolData.action + `?quoteId=${quoteIds.join(',')}`;

    let promise = this.scHttpService.getHttpService(url, "");
    promise.then(
      (data: any) => {
        let filteredData = data.filter(
          (data: any) => data.shipmentMode !== "Courier"
        );
        filteredData.forEach((data, index) => {
          const packageData = this.getPackagingSummary(data.shipmentItems);
          filteredData[index] = { ...filteredData[index], ...packageData };
        });
        this.bolDataList = filteredData;
        this.closeLoader();
        if (filteredData.length == 0) {
          this.getDocError();
          this.closeModal();
        }
      },
      (err) => {
        console.log(err);
        this.closeLoader();
        this.getDocError();
        this.closeModal();
      }
    );
  }

  download() {
    this._loaderService.stringSubject.next(true);
    setTimeout(() => {
      this.downloadBol();
    }, 50);
  }

  OpenPrintPdf(url: string = "") {
    if (!url) return;
    const modalRef = this.modalService.open(PrintPdfComponent, {
      size: "lg",
      centered: true,
      backdrop: true,
      windowClass: "my-custom-modal9 print-pdf",
    });
    modalRef.componentInstance.documentURL =
      this.sanitizer.bypassSecurityTrustResourceUrl(url);
    modalRef.result.then((result) => {
      // this.close();
    });
  }

  downloadBol() {
    const element = document.querySelector("#contentbol");
    const timeStamp = new Date().getTime();
    const opt = {
      margin: 0.1,
      filename: `BOL_${timeStamp}.pdf`,
      image: { type: "jpeg", quality: 0.8 },
      html2canvas: { dpi: 72, scale: 2, letterRendering: true },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
        compressPDF: true,
      },
      pageBreak: { mode: "css", after: ".break-page" },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        if (totalPages > 30) {
          this.pdfExceedsLimit();
          return Promise.reject("Page limit exceeded");
        }
      })
      .then(() => {
        return html2pdf().set(opt).from(element).save();
      })
      .then(() => {
        this.closeLoader();
        this.cdr.detectChanges();
      })
      .catch((err) => {
        this.pdfExceedsLimit();
      });
  }

  pdfExceedsLimit() {
    this.closeModal({ error: true });
    this.closeLoader();
    this.cdr.detectChanges();
  }

  printPdf() {
    this._loaderService.stringSubject.next(true);
    setTimeout(() => {
      this.generateBolPdfForUpload();
    }, 50);
  }

  async generateBolPdfForUpload() {
    let currentcomp = this;
    let element = document.querySelector("#contentbol");
    const name = `BOL_${new Date().getTime()}.pdf`;
    let opt = {
      margin: 0.1,
      filename: name,
      image: { type: "jpeg", quality: 0.8 },
      html2canvas: { dpi: 72, scale: 2, letterRendering: true },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
        compressPDF: true,
      },
      pageBreak: { mode: "css", after: ".break-page" },
    };
    let worker = await html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        if (totalPages > 30) {
          this.pdfExceedsLimit();
          return Promise.reject("Page limit exceeded");
        }

        return html2pdf().set(opt).from(element).toPdf().output("blob");
      })
      .then((data: Blob) => {
        return data;
      })
      .catch((err) => {
        this.pdfExceedsLimit();
        return null; // Return null or handle the rejection
      });
    
    if(worker){
      currentcomp.uploadBolData(worker);
    }
  }

  uploadBolData(pdfContent) {
    const formdata: FormData = new FormData();
    const dummyFile = new File([pdfContent], `BOL_${new Date().getTime()}.pdf`);
    formdata.append("file", dummyFile);

    let url = ServerURLS.uploadMultiData.action;

    let promise = this.scHttpService.postFileHttpService(url, formdata);

    promise.then(
      (res: any) => {
        this.OpenPrintPdf(res.downloadUrl);
        this.closeLoader();
        this.cdr.detectChanges();
      },
      (err: any) => {
        this.closeLoader();
        this.cdr.detectChanges();
      }
    );
  }

  closeModal(error = null) {
    this.activeModal.close(error);
  }
}
